body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*{
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header{
  position: sticky;
  top: 0;
  width: 100%;
  right: 0;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  background-color: white;
  box-shadow: 2px 2px 3px 1px #0000007d;
}
.logos {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
header .logos img{
 width: 50px;
 max-height: 35px;
}
.container{
  display: flex;
  height: 100%;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 2rem;
  gap: 3rem;
}
.card {
  max-width: 200px;
}
.card img {
  width: 100%;
  border: 1px solid #aba6a67d;
  box-shadow: 1px 1px 3px 1px #c3bebeb5;
  cursor: pointer;
  transition: all .3s ease ;
}
.card img:hover {
  /* border: 2.5px solid #aba6a67d; */
  transform: scale(1.06);
}
.card span {
  text-align: center;
  display: block;
}
.card a{
  text-align: center;
  display: block;
  text-decoration: none;
  color: cornflowerblue;
  margin-top: 0.5rem;
}
.overlay{
  position: fixed;
  inset: 0;
  background-color: #0000007d;
}
  .pdf-viewer {
    position: fixed;
    top: 10%;
    bottom: 10%;
}
